import React from 'react';
import Router from 'web-app/src/containers/app/Router';
import AppWrapper from './AppWrapper';
import { routes } from './routes';

const App = ({wrapInTheme}) => {
  return (
    <AppWrapper wrapInTheme={wrapInTheme}>
      <Router routes={routes}/>
    </AppWrapper>
  );
};

App.defaultProps = {
  wrapInTheme: true
};

export default App;
