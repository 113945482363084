import React from 'react';
import { Container, Box, ThemeModeToggle, Heading, Button } from 'shared-ui';
import { withFirebase } from 'react-recoil-firebase';

const DashboardPage = ({firebase}) => {
  return (
    <Box vfull vcenter withHeader>
      <Container>
        <Heading>Hey, this area is protected!</Heading>
        Let's get going!
        <Button onClick={() => {
          console.log('attempting a logout');
          console.log(firebase);
          const signOut = firebase.auth().signOut();
          console.log({signOut});
        }} type="accent-2">Log Out!</Button>
      </Container>
    </Box>
  );
};

export default withFirebase(DashboardPage);
