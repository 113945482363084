import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import { Button, Alert, Box } from 'shared-ui';
import { Input } from 'shared-ui/input';
import { FormItem } from 'shared-ui/formItem';
import { Form } from 'shared-ui/form';
import { hasErrors } from 'shared-ui/src/utils/form';
import { withFirebase } from 'react-recoil-firebase';
import SocialLogin from '../SocialLogin';
import { appRoute, baseRoutePaths } from '../../../constants/routes';

const initialValues = {
  email: '',
  password: ''
};

const Login = ({login}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const form = useForm({defaultValues: initialValues});
  const { errors, reset } = form;

  const onSubmit = async ({email, password}) => handleLogin({email, password});
  const handleLogin = async (options) => {
    setLoading(true);
    try {
      await login(options);
      navigate(appRoute(baseRoutePaths.DASHBOARD));
      console.log('redirecting somewhere...');
    } catch (e) {
      setError(e.message);
      console.log(e.message, e.code);
      setLoading(false);
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit}>
      {error && <Alert type="error" message={error} closable onClose={() => setError(null)}/>}

      <Box spacing={{bottom: 'base'}}><SocialLogin/></Box>

      <FormItem
        name="email"
        label={'Email Address'}
        rules={{
          required: true,
          email: true
        }}
        render={props => (
          <Input
            size="lg"
            type="text"
            placeholder="Email"
            {...props}
          />
        )}
      />

      <FormItem
        name="password"
        label="Password"
        rules={{
          required: true,
          minLength: 6
        }}
        render={props => (
          <Input
            size="lg"
            htmlType="password"
            placeholder="Password"
            {...props}
          />
        )}
      />

      <Button
        disabled={hasErrors(errors)}
        htmlType="submit"
        type="brand"
        size="lg"
        loading={loading}
      >
        Login
      </Button>
    </Form>
  );
};


export default withFirebase(Login);
