import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Alert, Box } from 'shared-ui';
import { Input } from 'shared-ui/input';
import { FormItem } from 'shared-ui/formItem';
import { Form } from 'shared-ui/form';
import { hasErrors } from 'shared-ui/src/utils/form';
import { withFirebase } from 'react-recoil-firebase';
//import { useProfile } from 'react-recoil-firebase/src/hooks/firestore/useProfile';
import SocialLogin from '../SocialLogin';
import { navigate } from '@reach/router';
import { appRoute, baseRoutePaths } from '../../../constants/routes';

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
  marketingOptIn: true
};

const SignUp = ({firebase, createUser, login, ...rest}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const form = useForm({defaultValues: initialValues});
  const { errors, getValues } = form;

  //const { data: profile } = useProfile();

  const onSubmit = async ({email, password}) => handleSignUp({email, password});

  const handleSignUp = async (options) => {
    setLoading(true);
    try {
      await createUser(options);
      console.log('redirecting somewhere...');
      navigate(appRoute(baseRoutePaths.DASHBOARD));
    } catch (e) {
      setError(e.message);
      console.log(e.message, e.code);
      setLoading(false);
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit}>
      {error && <Alert type="error" message={error} closable onClose={() => setError(null)}/>}

      <Box spacing={{bottom: 'base'}}><SocialLogin/></Box>

      <FormItem
        name="email"
        label={'Email Address'}
        rules={{
          required: true,
          email: true
        }}
        render={props => (
          <Input
            type="text"
            placeholder="Email"
            {...props}
          />
        )}
      />

      <FormItem
        name="password"
        label="Password"
        rules={{
          required: true,
          minLength: 6
        }}
        render={props => (
          <Input
            type="password"
            placeholder="Password"
            {...props}
          />
        )}
      />

      <FormItem
        name="confirmPassword"
        label="Confirm Password"
        rules={{
          required: true,
          minLength: 6,
          validate: {
            matchesPreviousPassword: (value) => {
              const { password } = getValues();
              return password === value || 'Passwords should match!';
            }
          }
        }}
        render={props => (
          <Input
            type="password"
            placeholder="Confirm Password"
            {...props}
          />
        )}
      />

      <Button
        disabled={hasErrors(errors)}
        htmlType="submit"
        type="brand"
        loading={loading}
      >
        Sign Up
      </Button>
    </Form>
  );
};

/*
<Button onClick={() => {
        console.log('attempting a logout');
        console.log(firebase);
        const signOut = firebase.auth().signOut();
        console.log({signOut});
      }}>Log Out!</Button>
 */

export default withFirebase(SignUp);
