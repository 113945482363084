import React from 'react';
import Login from '../../../containers/auth/Login';
import { Container, Box, Heading } from 'shared-ui';

const LoginPage = () => {
  return (
    <Box vfull vcenter withHeader>
      <Container type="tiny">
        <Heading level={1} spacing={{bottom: 'sm'}}>Login</Heading>
        <Login/>
      </Container>
    </Box>
  );
};

export default LoginPage;

