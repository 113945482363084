import React, { useState } from 'react';
import { Button } from 'shared-ui';
import { withFirebase } from 'react-recoil-firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { navigate } from '@reach/router';
import { appRoute, baseRoutePaths } from '../../../constants/routes';

const SocialLogin = ({login, className, style}) => {
  const [loading, setLoading] = useState(false);

  const handleLogin = async (options) => {
    setLoading(true);
    try {
      await login(options);
      console.log('redirecting somewhere...');
      navigate(appRoute(baseRoutePaths.DASHBOARD));
    } catch (e) {
      console.log(e.message, e.code);
      setLoading(false);
    }
  };

  return (
    <Button
      className={className}
      style={style}
      type="accent-1"
      size="sm"
      icon={<FontAwesomeIcon icon={faGoogle}/>}
      loading={loading}
      onClick={() => handleLogin({provider: 'google'})}
    >
      Sign in with Google!
    </Button>
  );
};


export default withFirebase(SocialLogin);
