import React from 'react';
import { Container, Box, Heading } from 'shared-ui';
import { withFirebase } from 'react-recoil-firebase';
import LiveWorkoutPlayer from 'web-app/src/containers/app/LiveWorkoutPlayer';

const LiveWorkoutPage = ({firebase}) => {
  return (
    <Box vfull vcenter withHeader>
      <Container>
        <Heading>Next workout starting in 10 min</Heading>
        <LiveWorkoutPlayer/>
      </Container>
    </Box>
  );
};

export default withFirebase(LiveWorkoutPage);
