import React from 'react';
import SignUp from '../../../containers/auth/SignUp';
import { Container, Box, ThemeModeToggle } from 'shared-ui';

const SignUpPage = () => {
  return (
    <Box vfull vcenter withHeader>
      <Container type="tiny">
        <h1 style={{marginBottom: 24}}>Sign Up</h1>
        <SignUp/>
      </Container>
    </Box>
  );
};

export default SignUpPage;
