import LoginPage from 'web-app/src/routes/app/LoginPage';
import SignUpPage from 'web-app/src/routes/app/SignUpPage';
import DashboardPage from 'web-app/src/routes/app/DashboardPage';
import { appRoute, baseRoutePaths } from '../../../constants/routes';
import LiveWorkoutPage from '../../../routes/app/LiveWorkoutPage';

export const routes = {
  HOME: {
    path: appRoute(),
    component: LiveWorkoutPage,
    privateRoute: false,
    adminRoute: false
  },
  LOGIN: {
    path: appRoute(baseRoutePaths.LOGIN),
    component: LoginPage,
    privateRoute: false,
    adminRoute: false
  },
  SIGNUP: {
    path: appRoute(baseRoutePaths.SIGNUP),
    component: SignUpPage,
    privateRoute: false,
    adminRoute: false
  },
  DASHBOARD: {
    path: appRoute(baseRoutePaths.DASHBOARD),
    component: DashboardPage,
    privateRoute: true,
    adminRoute: false
  }
};
