import React from 'react';
import { useDocumentData } from 'react-recoil-firebase/src/hooks/firestore/useDocument';
import { withFirebase } from 'react-recoil-firebase/src/context/firebase/withFirebase';
import { Loader } from 'shared-ui';
import loadable from '@loadable/component';

const WorkoutPlayer = loadable(() => import('web-app/src/components/WorkoutPlayer'));

const LiveWorkoutPlayer = ({firebase}) => {
  // TODO: Get schedule of workouts based on time-of-day (locally).

  // TODO: For now, let's just get a random workout.
  const { data, loading, error } = useDocumentData(`liveWorkout`, firebase.firestore().doc(`workouts/Mv39PxS5nASqvxuh34IL`));

  console.log({data, loading, error});

  return (
    <div>
      {loading && <Loader overlay={false}/>}
      {!loading && !error && (
        <WorkoutPlayer workout={data}/>
      )}
    </div>
  );
};

export default withFirebase(LiveWorkoutPlayer);
